<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Proyectos Cooperación Norte-Sur | Administración
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
            >
              Registrar proyecto
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :header-props="{
            sortByText: 'Ordenar por',
          }"
          :items="proyectos"
          @current-items="proyectosFiltrados"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          noResultsText="No se encontraron registros"
          loading-text="Cargando..."
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="6">
                  <v-text-field
                    filled
                    autocomplete="off"
                    dense
                    v-model="filtro"
                    prepend
                    label="Código / Nombre del proyecto"
                    maxlength="100"
                    @input="addToUrlParam"
                  >
                    <v-icon slot="append" color="black">
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="6">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosFiltroProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    item-text="nombreEstado"
                    item-value="id"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <export-excel
                    class="white--text mb-2 float-right v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default light-blue-502"
                    color="light-blue-502"
                    dark
                    :data="proyectos"
                    :fields="excelHeaders"
                    :title="`Proyectos de Cooperación Norte Sur`"
                    :footer="`${proyectos.length} proyectos registrados`"
                    name="ProyectosCNS.xls"
                  >
                    Exportar a excel
                    <v-icon class="ml-2" color="white"
                      >mdi-cloud-download-outline</v-icon
                    >
                  </export-excel>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.codigo }}</td>
              <td>{{ item.refSigeaci }}</td>
              <!--<td>{{ item.fechaSolicitudOpinionTecnica | formatDate }}</td>-->
              <td>{{ item.fechaSolicitudOpinionTecnica }}</td>
              <td>{{ item.nombreProyecto }}</td>

              <td>
                {{ item.nombreInstitucion }} <br />
                {{ item.nombreUnidadEjecutora }}
              </td>
              <td>
                <v-chip
                  label
                  class="ma-2"
                  :color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-4'
                      : item.estadoProyectoId === 3
                      ? 'deep-orange lighten-4'
                      : item.estadoProyectoId === 5
                      ? 'cyan lighten-4'
                      : item.estadoProyectoId === 6
                      ? 'cyan lighten-4'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-4'
                      : item.estadoProyectoId === 8
                      ? 'red lighten-4'
                      : 'blue-grey lighten-4'
                  "
                  :text-color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-1'
                      : item.estadoProyectoId === 3
                      ? 'deep-orange lighten-1'
                      : item.estadoProyectoId === 5
                      ? 'cyan lighten-1'
                      : item.estadoProyectoId === 6
                      ? 'cyan darken-1'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-1'
                      : item.estadoProyectoId === 8
                      ? 'red lighten-1'
                      : 'blue-grey lighten-1'
                  "
                  small
                >
                  {{ item.estadoProyecto }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarModalActualizar(
                      item.id,
                      item.codigo,
                      item.refSigeaci
                    )
                  "
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>

                <v-menu bottom offset-y dense>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-eraser-variant</v-icon>Enmiendas
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      :to="{
                        name: 'admin-enmiendas-fechas',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Enmiendas de fechas</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :to="{
                        name: 'admin-enmiendas-montos',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Enmiendas de montos</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :to="{
                        name: 'admin-enmiendas-componentes',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Enmiendas de componentes</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu
                  v-if="
                    !validacionTerritorio &&
                      (item.estadoProyectoId === 1 ||
                        item.estadoProyectoId === 2 ||
                        item.estadoProyectoId === 3 ||
                        item.estadoProyectoId === 4 ||
                        item.estadoProyectoId === 5)
                  "
                  bottom
                  offset-y
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-format-list-bulleted</v-icon>Mas acciones
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="
                        item.estadoProyectoId === 1 ||
                          item.estadoProyectoId === 2
                      "
                      :to="{
                        name: 'admin-poa',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Registro de Programación Anual de
                          proyecto</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="item.estadoProyectoId === 2"
                      :to="{
                        name: 'admin-iaff',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Registro de Informe de Avance Físico y
                          Financiero</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!--<v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  :to="{
                    name: 'detalle-cns',
                    params: {
                      codigoProyecto: item.codigo,
                      proyectoId: $CryptoJS.AES.encrypt(
                        item.id.toString(),
                        'KEYADMINCNS2022'
                      ).toString()
                    }
                  }"
                >
                  <v-icon left>mdi-eye</v-icon> Detalle
                </v-btn>

                <v-btn
                  v-if="item.estadoProyectoId === 1 || item.estadoProyectoId === 2"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  :to="{
                    name: 'admin-poa',
                    params: {
                      codigoProyecto: item.codigo,
                      proyectoId: $CryptoJS.AES.encrypt(
                        item.id.toString(),
                        'KEYADMINCNS2022'
                      ).toString()
                    }
                  }"
                >
                  <v-icon left>mdi-file</v-icon> POA
                </v-btn>

                <v-btn
                  v-if="item.estadoProyectoId === 2"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  :to="{
                    name: 'admin-iaff',
                    params: {
                      codigoProyecto: item.codigo,
                      proyectoId: $CryptoJS.AES.encrypt(
                        item.id.toString(),
                        'KEYADMINCNS2022'
                      ).toString()
                    }
                  }"
                >
                  <v-icon left>mdi-chart-bar</v-icon> IAFF
                </v-btn>-->

                <v-btn
                  v-if="
                    item.estadoProyectoId === 1 || item.estadoProyectoId === 2
                  "
                  class="ma-2  btn-bg-light"
                  small
                  depressed
                  color="blue-grey lighten-4"
                  @click="
                    mostrarDialogSuspension(
                      item.id,
                      item.codigo,
                      item.nombreProyecto,
                      1
                    )
                  "
                >
                  <v-icon left>mdi-power-off</v-icon> Suspender
                </v-btn>

                <v-btn
                  v-if="item.estadoProyectoId === 7"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarDialogSuspension(
                      item.id,
                      item.codigo,
                      item.nombreProyecto,
                      2
                    )
                  "
                >
                  <v-icon left>mdi-power</v-icon> Activar
                </v-btn>

                <v-btn
                  class="ma-2  btn-bg-light"
                  small
                  depressed
                  color="blue-grey lighten-4"
                  @click="
                    mostrarDialogCambioEstado(
                      item.id,
                      item.codigo,
                      item.nombreProyecto,
                      item.estadoProyectoId
                    )
                  "
                >
                  <v-icon left>mdi-playlist-edit</v-icon> Cambiar estado
                </v-btn>

                <v-btn
                  v-if="1 < 1"
                  class="ma-2  btn-bg-light"
                  small
                  depressed
                  color="blue-grey lighten-4"
                  @click="obtenerDatosItemFicha(item.id)"
                >
                  <v-icon left dark>mdi-file-document</v-icon> Generar ficha
                  General
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: dialog suspender proyecto -->
    <v-dialog
      v-model="dialogSuspension"
      transition="scroll-y-transition"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Suspensión del proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogSuspension = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de suspender el proyecto
                {{ codigoProyectoSeleccionado }}?
                <br />
                {{ proyectoSeleccionado }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogSuspension = false"
            :disabled="btnConfirmacionLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="suspenderProyecto"
            :loading="btnConfirmacionLoading"
          >
            Sí, suspender
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog cambio de estado del proyecto -->
    <v-dialog
      v-model="dialogCambioEstado"
      transition="scroll-y-transition"
      width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Cambio de estado del proyecto {{ codigoProyectoSeleccionado }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogCambioEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p class="text-h6">
                Seleccione el estado para el proyecto "{{
                  proyectoSeleccionado
                }}"
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="6">
              <v-select
                v-model="nuevoEstadoProyecto"
                :items="estadosProyecto"
                :loading="false"
                dense
                filled
                label="Estado"
                item-text="nombreEstado"
                item-value="id"
                :no-data-text="'No existen estados registrados'"
                menu-props="auto"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12">
              <p class="text-subtitle-1"></p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogCambioEstado = false"
            :disabled="btnConfirmacionLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoProyecto"
            :loading="btnConfirmacionLoading"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <ModalRegistroProyectoSinOT
      ref="ModalRegistro"
      @get-items="obtenerItems"
      :proyectoId="proyectoId"
      :proyectoCodigo="proyectoCodigo"
      :accion="accion"
      :titulo="modalTitle"
      @actualizar-accion="actualizarAccion"
    />

    <v-dialog
      v-model="dialogRegistro"
      persistent
      max-width="1280px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <div class="row mt-0 mb-0">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          {{ modalTitle }} | {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-2">
              Información General 
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-1">
              Alcance
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Datos Financieros y Proy
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
              Plazos del proyecto
              <v-icon>mdi-av-timer</v-icon>
            </v-tab>

            <v-tab href="#tab-5">
              Cobertura y Vinculación
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6">
              Beneficiarios
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-7">
              Carga de documentos
              <v-icon>mdi-file-upload-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-8">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-9" @click="actualizarAvanceProyecto(idProyecto)">
              Confirmar información
              <v-icon>mdi-check-all</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SeccionInformacionGeneralProyecto
                    ref="seccionInformacionGeneral"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    @seccion-condiciones-desembolsos="
                      cuentaCondicionesDesembolsos
                    "
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <SeccionAlcanceProyecto
                    ref="seccionAlcance"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <SeccionDatosFinancierosProyecto
                    ref="seccionDatosFinancieros"
                    :id="idProyecto"
                    :codSigeaci="refSigeaci"
                    @actualizar-ejecucion-subdivsion-cobertura="
                      actualizarEjecucionSubdivisionTerritorio
                    "
                    @habilitar-cobertura-territorial="
                      habilitarCoberturaTerritorial
                    "
                    :tipoUsuario="`int`"
                    @obtenerCoberturaTerritoralVinculacion="
                      actualizarEjecucionSubdivisionTerritorio(idProyecto)
                    "
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <SeccionPlazosProyecto
                    ref="seccionPlazos"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <SeccionCoberturaVinculacion
                    ref="seccionCoberturaVinculacion"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <SeccionBeneficiariosProyecto
                    ref="seccionBeneficiariosProyecto"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <SeccionDocumentosProyecto
                    ref="seccionDocumentos"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-8'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <SeccionContactosProyectoCns
                    ref="seccionContactos"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                    :iaff="false"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-9'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <SeccionConfirmarProyecto
                    ref="SeccionConfirmarProyecto"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                    @obtener-proyectos="obtenerItems"
                    @mostrar-dialog-confirmar="mostrarDialogConfirmacion"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogConfirmacion"
      width="1200"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmación de la información

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-tabs
            v-model="tabConfirmacion"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-2">
              Información General del Proyecto
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-1">
              Alcance del proyecto
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Datos Financieros y Proyección
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
              Plazos del proyecto
              <v-icon>mdi-av-timer</v-icon>
            </v-tab>

            <v-tab href="#tab-5">
              Cobertura y Vinculación
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6">
              Beneficiarios
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-7">
              Carga de documentos
              <v-icon>mdi-file-upload-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-8">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabConfirmacion" touchless>
            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          informacionGeneralRegistrada
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          informacionGeneralRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p
                          v-if="informacionGeneralRegistrada"
                          class="mt-1 mb-1"
                        >
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionInformacionGeneralProyecto
                    ref="seccionInformacionGeneralConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          alcanceRegistrado
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          alcanceRegistrado
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="alcanceRegistrado" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <SeccionAlcanceProyecto
                    ref="seccionAlcanceConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          datosFinancierosRegistrados
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          datosFinancierosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="datosFinancierosRegistrados" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionDatosFinancierosProyecto
                    ref="seccionDatosFinancierosConfirmacion"
                    :id="idProyecto"
                    :codSigeaci="refSigeaci"
                    @actualizar-ejecucion-subdivsion-cobertura="
                      actualizarEjecucionSubdivisionTerritorio
                    "
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                    @obtenerCoberturaTerritoralVinculacion="
                      actualizarEjecucionSubdivisionTerritorio(idProyecto)
                    "
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          fechasPlazosRegistradas
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          fechasPlazosRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="fechasPlazosRegistradas" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionPlazosProyecto
                    ref="seccionPlazosConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          coberturaRegistrada
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          coberturaRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="coberturaRegistrada" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionCoberturaVinculacion
                    ref="seccionCoberturaVinculacionConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                    @obtener-cobertura-territorial-vinculacion="
                      obtenerMontosSuscritosDepto(idProyecto)
                    "
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p
                          v-if="
                            beneficiariosRegistrados &&
                              institucionesBeneficiariasRegistradas
                          "
                          class="mt-1 mb-1"
                        >
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionBeneficiariosProyecto
                    ref="seccionBeneficiariosProyectoConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          documentosRegistrados
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          documentosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="documentosRegistrados" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionDocumentosProyecto
                    ref="seccionDocumentosConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-8'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          contactosRegistrados
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          contactosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="contactosRegistrados" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionContactosProyectoCns
                    ref="seccionContactosConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                    :iaff="false"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <v-row class="mt-2">
            <v-col cols="12">
              <p class="text-subtitle-1 mb-1 pb-0" v-if="datosPendientes > 0">
                Existe información pendiente de registrar para el proyecto
                {{ codigoProyectoCNS }}.
              </p>
              <p class="text-subtitle-1 mb-1 pb-0" v-if="datosPendientes > 0">
                Por favor, complete la información e inténtelo nuevamente
              </p>
              <p class="text-subtitle-1" v-if="datosPendientes > 0">
                Información pendiente de registrar:
              </p>

              <v-list>
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          informacionGeneralRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          informacionGeneralRegistrada
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Información General
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          alcanceRegistrado
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          alcanceRegistrado
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Alcance
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          datosFinancierosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          datosFinancierosRegistrados
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Datos financieros
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          fechasPlazosRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          fechasPlazosRegistradas
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Plazos del proyecto
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          coberturaRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          coberturaRegistrada
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Cobertura y vinculación
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Beneficiarios del proyecto
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          documentosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          documentosRegistrados
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Carga de Documentos del proyecto
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!--  <v-list-item style="height: 25px; min-height: 25px;">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-1">
                        <v-icon small :color="documentoOpiniontecnicaRegistrado ? 'blue darken-2' : 'orange darken-2'">
                          {{documentoOpiniontecnicaRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                        </v-icon> Documento de opinión técnica
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 25px; min-height: 25px;">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-1">
                        <v-icon small :color="documentoProyectoRegistrado ? 'blue darken-2' : 'orange darken-2'">
                          {{documentoProyectoRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                        </v-icon> Documento del proyecto
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 25px; min-height: 25px;">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-1">
                        <v-icon small :color="documentoConvenioRegistrado ? 'blue darken-2' : 'orange darken-2'">
                          {{documentoConvenioRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                        </v-icon> Documento de convenio
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
-->
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          contactosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          contactosRegistrados
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Contactos
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  class="mt-4"
                  style="height: 25px; min-height: 25px;"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-chip
                        class="ma-2"
                        label
                        :color="
                          porcentajeAvance == 100
                            ? `light-blue lighten-2`
                            : porcentajeAvance >= 0 && porcentajeAvance <= 10
                            ? `red lighten-2`
                            : porcentajeAvance < 10 && porcentajeAvance <= 30
                            ? `deep-orange lighten-2`
                            : porcentajeAvance < 30 && porcentajeAvance <= 50
                            ? `orange lighten-2`
                            : porcentajeAvance < 50 && porcentajeAvance <= 70
                            ? `yellow lighten-2`
                            : porcentajeAvance < 70 && porcentajeAvance <= 90
                            ? `lime lighten-2`
                            : porcentajeAvance < 90 && porcentajeAvance <= 99
                            ? `green lighten-2`
                            : `blue`
                        "
                      >
                        {{ porcentajeAvance }}% información registrada
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- {{datosProyecto}}-->

              <p class="text-subtitle-1 mt-2" v-if="datosPendientes <= 0">
                ¿Está seguro de confirmar la información del proyecto
                {{ codigoProyectoCNS }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <!--<v-divider></v-divider>-->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="confirmarProyecto"
            :loading="btnEnvioLoading"
            :disabled="porcentajeAvance < 100"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--inicio:: impresion ficha general -->
    <v-dialog
      max-width="1200px"
      v-model="dialogPreviewFicha"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <div class="row mt-0 mb-0">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          Ficha general del proyecto | {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogPreviewFicha = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <template>
            <div ref="html2Pdf" id="fichaGeneral">
              <!--@progress="onProgress($event)"-->

              <!-- PDF Content Here -->
              <div class="row">
                <div class="col col-md-12 col-sm-12">
                  <img width="150" src="media/logos/logo-light.png" />
                </div>
              </div>
              <div class="row mt-6" style="border: 1px solid red;">
                <div class="col col-sm-12 col-md-12">
                  <p
                    class="text-h6 text-uppercase text-center font-weight-medium black--text"
                    style="font-weight: 600; padding-bottom: 0px; margin-bottom: 1px;text-align: center"
                  >
                    SISTEMA DE COOPERACIÓN - SICOOPERAGT
                  </p>
                  <p
                    class="text-h6 text-uppercase text-center font-weight-medium black--text"
                    style="font-weight: 600; padding-top:5px; margin-bottom: 5px; margin-top: 5px"
                  >
                    Ficha General del Proyecto
                  </p>
                  <hr />
                </div>
              </div>

              <div class="row">
                <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Código SICOOPERA</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.codigo
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Código SIGEACI</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.refSigeaci
                          ? datosProyecto.refSigeaci
                          : "N/A"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Nombre del Proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.nombreProyecto
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Fecha de solicitud de la opinión
                        técnica</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.fechaSolicitudOpinionTecnica
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Estado del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.estadoProyecto
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12">
                  <hr />
                </v-col>
              </div>

              <!--begin:: row -->
              <div class="row">
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Institución</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.nombreInstitucion
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Unidad Ejecutora</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.nombreUnidadEjecutora
                          ? datosProyecto.nombreUnidadEjecutora
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <hr />
                </v-col>
              </div>
              <!--end:: row -->

              <!--begin:: fuente cooperante row -->
              <div class="row">
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Tipo de Fuente Cooperante</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.tiposFuenteCooperanteId === 1
                          ? `Bilateral`
                          : `Multilateral`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Fuente Cooperante</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.tiposFuenteCooperanteId === 1
                          ? datosProyecto.nombreFuenteBilateral
                          : datosProyecto.nombreFuenteMultilateral
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Agencia</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.tiposFuenteCooperanteId === 1
                          ? datosProyecto.agenciaBilateral
                          : datosProyecto.agencia
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="12">
                  <hr />
                </v-col>
              </div>
              <!--end:: row -->

              <!--begin:: Justi row -->
              <div class="row">
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Descripción del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.descripcionProyecto
                          ? datosProyecto.descripcionProyecto
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Antecedentes del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.antecedentes
                          ? datosProyecto.antecedentes
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Problemática</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.problematica
                          ? datosProyecto.problematica
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Justificación del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.justificacion
                          ? datosProyecto.justificacion
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                  <v-subheader class="text-h6 black--text">
                    Situación del proyecto
                  </v-subheader>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Situación sin proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.situacionSinProyecto
                          ? datosProyecto.situacionSinProyecto
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Situación con proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.situacionConProyecto
                          ? datosProyecto.situacionConProyecto
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                  <v-subheader class="text-h6 black--text">
                    Objetivos del proyecto
                  </v-subheader>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Objetivo general del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.objetivoGeneral
                          ? datosProyecto.objetivoGeneral
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Objetivo específico 1</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.objetivoEspecifico1
                          ? datosProyecto.objetivoEspecifico1
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Objetivo específico 2</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.objetivoEspecifico2
                          ? datosProyecto.objetivoEspecifico2
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Objetivo específico 3</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.objetivoEspecifico3
                          ? datosProyecto.objetivoEspecifico3
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </div>
              <!--end:: row -->

              <!--begin:: Contactto row -->
              <div class="row mt-4">
                <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                  <v-subheader class="text-h6 black--text">
                    Contactos del proyecto
                  </v-subheader>
                </v-col>

                <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Teléfono directo del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.telefonoDirectoProyecto
                          ? datosProyecto.telefonoDirectoProyecto
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pt-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6 font-weight-bold"
                        >Dirección física del proyecto</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        datosProyecto.DireccionFisicaProyecto
                          ? datosProyecto.DireccionFisicaProyecto
                          : `N/A`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </div>
              <!--end:: row -->
            </div>
          </template>

          <v-row>
            <v-col cols="12" md="12">
              <v-btn
                color="light-blue-502"
                dark
                class="white--text mb-2 float-right"
                @click="generarJSPDP"
              >
                Exportar ficha general
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: impresion ficha general -->
  </div>
</template>

<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
//import VueHtml2pdf from 'vue-html2pdf';
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";

import moment from "moment";
import {
  OBTENER_PROYECTOS_CNS,
  CONFIRMAR_PROYECTO_CNS,
  OBTENER_AVANCE_PROYECTO_CNS
} from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import validations from "@/core/untils/validations.js";

import ModalRegistroProyectoSinOT from "@/view/pages/proyectos-cns/RegistroProyectosCnsSinOt.vue";
import SeccionAlcanceProyecto from "@/view/pages/proyectos-cns/externo/SeccionAlcanceProyecto.vue";
import SeccionInformacionGeneralProyecto from "@/view/pages/proyectos-cns/externo/SeccionInformacionGeneralProyecto.vue";
import SeccionDocumentosProyecto from "@/view/pages/proyectos-cns/externo/SeccionDocumentosProyecto.vue";

import SeccionDatosFinancierosProyecto from "@/view/pages/proyectos-cns/externo/SeccionDatosFinancierosProyecto.vue";
import SeccionPlazosProyecto from "@/view/pages/proyectos-cns/externo/SeccionPlazosProyecto.vue";
import SeccionCoberturaVinculacion from "@/view/pages/proyectos-cns/externo/SeccionCoberturaVinculacion.vue";
import SeccionBeneficiariosProyecto from "@/view/pages/proyectos-cns/externo/SeccionBeneficiariosProyecto.vue";
import SeccionContactosProyectoCns from "@/view/pages/proyectos-cns/externo/SeccionContactosProyectoCns.vue";
import SeccionConfirmarProyecto from "@/view/pages/proyectos-cns/externo/SeccionConfirmarProyecto.vue";

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
export default {
  name: "AdminProyectosCns",
  components: {
    //VueHtml2pdf,
    SnackAlert,
    DialogLoader,
    DivisorColor,

    ModalRegistroProyectoSinOT,
    SeccionAlcanceProyecto,
    SeccionInformacionGeneralProyecto,
    SeccionDocumentosProyecto,
    SeccionDatosFinancierosProyecto,
    SeccionPlazosProyecto,
    SeccionCoberturaVinculacion,
    SeccionBeneficiariosProyecto,
    SeccionContactosProyectoCns,
    SeccionConfirmarProyecto
  },
  data() {
    return {
      validacionTerritorio: false,
      accion: 1,
      btnConfirmacionLoading: false,
      btnEnvioLoading: false,
      dialogLoaderVisible: false,
      dialogPreviewFicha: false,
      dialogLoaderText: "Recuperando información...",
      tableLoading: false,
      validForm: false,
      ddRolesLoading: false,
      switchItemEstado: true,
      dialogRegistro: false,
      dialogSuspension: false,
      proyectoSeleccionado: "",
      proyectoSeleccionadoId: 0,
      codigoProyectoSeleccionado: "",
      codigoProyecto: "",
      refSigeaci: "",
      tipoEstadoSeleccionado: 0,
      idProyecto: 0,
      proyectoId: 0,
      proyectoCodigo: "",
      proyectos: [],
      filtro: "",
      dialog: false,
      tab: "tab-2",
      modalTitle: "Registrar nuevo proyecto",
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyecto: "",
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "Registro" },
        { value: 2, text: "Seguimiento" },
        { value: 6, text: "Finalizado" },
        { value: 7, text: "Suspendido" },
      ],
      proyectosFiltrados: [],
      estadosFiltroProyecto: [],
      nuevoEstadoProyecto: {},
      ...validations,
      estadoProyectoId: 0,
      dialogCambioEstado: false,
      datosProyecto: [],
      dialogConfirmacion: false,
      tabConfirmacion: "tab-2",
      excelHeaders: {
        "Codigo SICOOPERA": "codigo",
        "Código SIGEACI": "refSigeaci",
        "Fecha de solicitud opinión Técnica": "fechaSolicitudOpinionTecnica",
        "Nombre del Proyecto": "nombreProyecto",
        Institución: "nombreInstitucion",
        "Unidad Ejecutora": "nombreUnidadEjecutora",
        /*'Tipo de Fuente Cooperante' : {
                field: 'tipoFuenteCooperante',
                callback: (value) => {
                    return `Landline Phone - ${value}`;
                } ,*/
        Estado: "estadoProyecto",

        /*  'Telephone 2' : {
                field: 'phone.landline',
                callback: (value) => {
                    return `Landline Phone - ${value}`;
                } 
            },*/
      },
      informacionGeneralRegistrada: false,
      alcanceRegistrado: false,
      contactosRegistrados: false,
      datosFinancierosRegistrados: false,
      fechasPlazosRegistradas: false,
      beneficiariosRegistrados: false,
      institucionesBeneficiariasRegistradas: false,
      coberturaRegistrada: false,
      documentosRegistrados: false,
      documentoJustificacionRegistrado: false,
      documentoOpiniontecnicaRegistrado: false,
      documentoProyectoRegistrado: false,
      documentoConvenioRegistrado: false,
      datosPendientes: 0,
      porcentajeAvance: 0,
      porcentajeDatosFinancieros: 0,
      codigoProyectoCNS: "",
    };
  },
  methods: {
    filtroEstado(value) {
      //console.log(value)
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoProyecto.id) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoProyecto.nombreEstado;
    },

    /*
     * Obtener los estados del proyecto
     */
    async obtenerEstadosProyecto() {
      this.estadosProyecto = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "EstadosProyecto/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.estadosProyecto = res.data;

            this.setEstadosFiltro(res.data);
            //

            // delete this.estadosFiltroProyecto
          }
        })
        .catch(() => {
          this.estadosProyecto = [];
        });
    },

    setEstadosFiltro(estados) {
      this.estadosFiltroProyecto = [];
      this.estadosFiltroProyecto = estados;
      this.estadosFiltroProyecto.push({ nombreEstado: "Todos", id: null });
    },

    //Obtener los proyectos registrados
    async obtenerItems() {
      this.tableLoading = true;

      this.proyectos = [];
      await this.$store
        .dispatch(OBTENER_PROYECTOS_CNS, { estadoProyectoId: 0 })
        .then((res) => {
          if (res.status == 200) {
            this.proyectos = this.$store.state.proyectocns.proyectos;
          }

          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    modalNuevo() {
      //this.dialogRegistro = true;
      this.accion = 1;
      this.modalTitle = "Registrar Proyecto";
      this.$refs.ModalRegistro.resetItems(2);
      this.$refs.ModalRegistro.mostrarModal();
    },

    /*  obtenerDatosItem(id){
      this.$refs.ModalRegistro.mostrarModal(obtenerDatosGenerales);
    },*/

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
      this.$refs.ModalRegistro.ocultarModal();
    },

    mostrarModalActualizar(id, codigo, refSigeaci) {
      /* this.dialogLoaderVisible=true;
      this.modalTitle="Actualizar Proyecto";
      this.tab = "tab-1";
      this.accion=2;
      this.proyectoId=id;
      //console.log(this.idProyecto)
      this.proyectoCodigo = codigo;

       //console.log(test);
      if(this.$refs.ModalRegistro){
        this.$refs.ModalRegistro.resetItems(1);

        this.$refs.ModalRegistro.obtenerDatosGenerales(this.proyectoId).then(()=> {
          //this.$refs.ModalRegistro.obtenerTiposFuenteCooperante();
            this.dialogLoaderVisible=false;
            this.$refs.ModalRegistro.mostrarModal();
        });
      }
      */

      this.dialogLoaderText = "Obteniendo información...";
      this.dialogLoaderVisible = true;

      this.tab = "tab-2";
      this.idProyecto = id;
      //console.log(this.idProyecto)
      this.codigoProyecto = codigo;
      this.refSigeaci = refSigeaci;
      //console.log(test);
      if (this.$refs.seccionInformacionGeneral) {
        this.$refs.seccionInformacionGeneral.resetItems(2, id);

        /*this.$refs.seccionInformacionGeneral.obtenerInstituciones().then(() => {
          this.$refs.seccionInformacionGeneral.obtenerTiposFuenteCooperante();

          this.$refs.seccionInformacionGeneral
            .obtenerFuentesBilaterales()
            .then(() => {
              this.$refs.seccionInformacionGeneral
                .obtenerFuentesMultilateral()
                .then(() => {
                  this.$refs.seccionInformacionGeneral.obtenerDatosItem(
                    this.idProyecto
                  );
                });
            });
        });*/
      }

      if (this.$refs.seccionAlcance) {
        this.$refs.seccionAlcance.resetItems(id);
        //this.$refs.seccionAlcance.obtenerDatosItem(this.idProyecto);
      }

      if (this.$refs.seccionDatosFinancieros) {
        this.$refs.seccionDatosFinancieros.resetItems(2, id);
        /*this.$refs.seccionDatosFinancieros.obtenerTiposDatosFinancieros();
        this.$refs.seccionDatosFinancieros.obtenerTiposDatosFinancierosDistribucion();
        this.$refs.seccionDatosFinancieros.obtenerDatosEspecificosProyecto(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.resetItems();
        this.$refs.seccionDatosFinancieros.obtenerMecanismosProyecto(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerDatosFinancieros(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerDatosDistribucionFuenteCooperante(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerProyeccionesDesembolsos(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerMontosSuscritosDepto(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerEjecucionesPorActividad(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerEjecucionesPorActividadContrapartida(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerSubDivisiones();

        this.$refs.seccionDatosFinancieros.obtenerReferenciasSubdivisionFC(
          this.refSigeaci
        );
        this.$refs.seccionDatosFinancieros.obtenerReferenciasSubdivisionCP(
          this.refSigeaci
        );

        this.$refs.seccionDatosFinancieros.obtenerEjecucionesPorActividadDeptoMuni(
          this.idProyecto
        );*/
      }

      if (this.$refs.seccionPlazos) {
        this.$refs.seccionPlazos.resetItems(id);
        /*this.$refs.seccionPlazos.obtenerDatosItem(this.idProyecto);
        this.$refs.seccionPlazos.obtenerCondicionesDesembolsos(this.idProyecto);
        this.$refs.seccionPlazos.obtenerTiposCondicionesDesembolso();*/
      }

      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.resetItems(1, id);

        /*this.$refs.seccionCoberturaVinculacion
          .obtenerDatosGeneralesProyecto(this.idProyecto)
          .then(() => {
            this.$refs.seccionCoberturaVinculacion.obtenerCoberturaProyecto(
              this.idProyecto
            );
            this.$refs.seccionCoberturaVinculacion.obtenerMontosSuscritosDepto(
              this.idProyecto
            );
          });

        this.$refs.seccionCoberturaVinculacion.obtenerSectoresBeneficiados();
        this.$refs.seccionCoberturaVinculacion.obtenerDepartamentos();
        this.$refs.seccionCoberturaVinculacion.obtenerPnds();
        this.$refs.seccionCoberturaVinculacion.obtenerOds();
        this.$refs.seccionCoberturaVinculacion.obtenerEjesKatun();
        this.$refs.seccionCoberturaVinculacion.obtenerPgg();
        //this.$refs.seccionCoberturaVinculacion.obtenerActividades();

        //this.$refs.seccionCoberturaVinculacion.obtenerCad();
        this.$refs.seccionCoberturaVinculacion.obtenerPoliticasPublicas();*/
      }

      if (this.$refs.seccionBeneficiariosProyecto) {
        this.$refs.seccionBeneficiariosProyecto.resetItems(1, id);
        /*this.$refs.seccionBeneficiariosProyecto.obtenerDatosSeccion(
          this.idProyecto
        );
        this.$refs.seccionBeneficiariosProyecto.obtenerBeneficiarios(
          this.idProyecto
        );
        this.$refs.seccionBeneficiariosProyecto.obtenerBeneficiariosInstitucion(
          this.idProyecto
        );

        this.$refs.seccionBeneficiariosProyecto.obtenerGeneros();
        this.$refs.seccionBeneficiariosProyecto.obtenerAreasBeneficiadas();
        this.$refs.seccionBeneficiariosProyecto.obtenerEtnias();
        this.$refs.seccionBeneficiariosProyecto.obtenerRangosEdad();
        this.$refs.seccionBeneficiariosProyecto.obtenerTiposIngreso();*/
      }

      if (this.$refs.seccionDocumentos) {
        this.$refs.seccionDocumentos.resetItems(2, id);

        /*this.$refs.seccionDocumentos
          .obtenerDatosGeneralesProyecto(this.idProyecto)
          .then(() => {
            this.$refs.seccionDocumentos.obtenerDocumentosCargados(
              this.idProyecto
            );
          });*/
        //this.$refs.seccionDocumentos.obtenerDocumentosCns();
        //this.$refs.seccionDocumentos.obtenerDocumentosCargados(this.idProyecto,1 );
      }

      if (this.$refs.seccionContactos) {
        this.$refs.seccionContactos.resetItems(1, id);
        /* this.$refs.seccionContactos
          .obtenerDatosGeneralesProyecto(this.idProyecto)
          .then(() => {
            this.$refs.seccionContactos.resetItems(1);
            this.$refs.seccionContactos.obtenerTiposContacto();
            this.$refs.seccionContactos.obtenerContactos(this.idProyecto);
          }); */
      }

      if (this.$refs.SeccionConfirmarProyecto) {
        this.$refs.SeccionConfirmarProyecto.obtenerAvanceProyectoCns(
          this.idProyecto
        );
      }

      setTimeout(() => {
        this.dialogLoaderVisible = false;
        this.dialogRegistro = true;
      }, "2000");
    },

    /*
     * Mostrar el dialogo para la suspension/activacion del proyecto
     * tipo: 1 suspender, 2 activar
     * */
    mostrarDialogSuspension(id, codigoProyecto, nombreProyecto, tipo) {
      this.proyectoSeleccionado = nombreProyecto;
      this.codigoProyectoSeleccionado = codigoProyecto;
      this.proyectoSeleccionadoId = id;
      this.tipoEstadoSeleccionado = tipo;
      this.dialogSuspension = true;
    },

    mostrarDialogCambioEstado(
      id,
      codigoProyecto,
      nombreProyecto,
      estadoProyectoId
    ) {
      this.proyectoSeleccionado = nombreProyecto;
      this.codigoProyectoSeleccionado = codigoProyecto;
      this.proyectoSeleccionadoId = id;
      this.estadoActualId = estadoProyectoId;

      this.nuevoEstadoProyecto = this.estadosProyecto.find(
        (item) => item.id == this.estadoActualId
      );

      this.dialogCambioEstado = true;
    },

    //Suspensión del proyecto
    async suspenderProyecto() {
      this.nuevoEstadoProyecto.id = 8;
      this.actualizarEstadoProyecto();
      //this.$refs.snackalert.SnackbarShow("success", "Mensaje",`Se ha actualizado el proyecto con éxito`);
    },

    //Actualizar el estado del proyecto
    async actualizarEstadoProyecto() {
      if (this.nuevoEstadoProyecto.nombreEstado == "Todos") {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Error!",
          `Debe seleccionar un estado válido para el proyecto`
        );
        return;
      }

      this.btnEnvioLoading = true;

      await this.$store
        .dispatch(CONFIRMAR_PROYECTO_CNS, {
          id: this.proyectoSeleccionadoId,
          estadoId: this.nuevoEstadoProyecto.id,
        })
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogCambioEstado = false;
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnEnvioLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnEnvioLoading = false;
        });
    },

    async generarFichaProyecto(id) {
      this.obtenerDatosItem(id).then(() => {
        this.$refs.html2Pdf.generatePdf();
      });
    },

    //Obtener los datos de un proyecto
    async obtenerDatosItem(idProyecto) {
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información del proyecto...";
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto,
        })
        .then((res) => {
          if (res.status === 200) {
            this.datosProyecto = res.data;
          }
          this.dialogLoaderVisible = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.dialogLoaderVisible = false;
        });
    },

    actualizarEjecucionSubdivisionTerritorio(proyectoId) {
      // console.log("aqui......... " + proyectoId)
      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.obtenerMontosSuscritosDepto(
          proyectoId
        );
      }
    },

    resetForm() {},

    actualizarAccion(accion) {
      this.accion = accion;
    },

    async obtenerDatosItemFicha(id) {
      this.obtenerDatosItem(id).then(() => {
        this.codigoProyecto = this.datosProyecto.codigo;
        this.dialogPreviewFicha = true;
      });
    },

    generarJSPDP() {
      //window.html2canvas = html2canvas;

      //var doc = new jsPDF('p', 'pt', 'letter');
      const html = this.$refs.html2Pdf.innerHTML;

      //const html = document.querySelector("#fichaGeneral");
      console.log(html);

      /*doc.addHTML(html,{
          function(bla){doc.save('Proyecto.pdf'); console.log(bla)},
        });*/

      const doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "a4",
        hotfixes: ["px_scaling"],
      });

      html2canvas(this.$refs.html2Pdf, {
        width: doc.internal.pageSize.getWidth(),
        height: doc.internal.pageSize.getHeight(),
      })
        .then(() => {
          //const img = canvas.toDataURL("image/png");

          //doc.addImage(img, "PNG", 140, 10, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
          doc.save("p&lstatement.pdf");
        })
        .catch((error) => {
          console.log(error);
        });

      /*const margins = {
          top: 80,
          bottom: 60,
          left: 40,
          width: 522
        };*/
      /*doc.addHTML(
          html, 
          margins.left,
          margins.top,{
          width: margins.width
          },
          function(bla){doc.save('Proyecto.pdf'); console.log(bla)},
          margins
        
          )*/

      /*doc.addHTML(html,
            function(pdf){
              pdf.save("Proyecto.pdf")
            }
          )*/
    },

    addToUrlParam() {
      //this.$router.push({ query: { text: this.filtro } });
      localStorage.setItem("filtroProyecto", this.filtro);
    },

    cuentaCondicionesDesembolsos(tipoCooperacionId) {
      let accion = 1;
      if (tipoCooperacionId != 1) {
        accion = 2;
      }

      if (this.$refs.seccionPlazos) {
        this.$refs.seccionPlazos.habilitarSeccionCondicionesDesembolsos(accion);
      }
    },

    //Actualizar el avance del proyecto
    actualizarAvanceProyecto(proyectoId) {
      if (this.$refs.SeccionConfirmarProyecto) {
        this.$refs.SeccionConfirmarProyecto.obtenerAvanceProyectoCns(
          proyectoId
        );
      }
    },

    //Habilitar o deshabilitar la subsección de cobertura territorial en la sección de cobertura y vinculación
    //Tipo: 1 habilitado, 2 deshabilitado
    habilitarCoberturaTerritorial(tipo) {
      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.habilitarCoberturaTerritorial(
          tipo
        );
      }
    },

    mostrarDialogConfirmacion(idProyecto, codigo) {
      //console.log(idProyecto)
      //console.log(codigo)
      this.idProyecto = idProyecto;
      this.idProyectoSeleccionado = idProyecto;
      this.dialogLoaderVisible = true;
      this.codigoProyectoCNS = codigo;
      this.datosProyecto = [];
      this.tabConfirmacion = "tab-2";

      if (this.$refs.seccionInformacionGeneralConfirmacion) {
        this.$refs.seccionInformacionGeneralConfirmacion.resetItems(2);
      }

      if (this.$refs.seccionAlcanceConfirmacion) {
        this.$refs.seccionAlcanceConfirmacion.resetItems();
        this.$refs.seccionAlcanceConfirmacion.obtenerDatosItem(this.idProyecto);
      }

      if (this.$refs.seccionDatosFinancierosConfirmacion) {
        this.$refs.seccionDatosFinancierosConfirmacion.resetItems(
          2,
          this.idProyecto
        );
      }

      if (this.$refs.seccionPlazosConfirmacion) {
        this.$refs.seccionPlazosConfirmacion.resetItems(this.idProyecto);
        /*this.$refs.seccionPlazos.obtenerDatosItem(this.idProyecto);
        this.$refs.seccionPlazos.obtenerCondicionesDesembolsos(this.idProyecto);
        this.$refs.seccionPlazos.obtenerTiposCondicionesDesembolso();*/
      }

      if (this.$refs.seccionCoberturaVinculacionConfirmacion) {
        this.$refs.seccionCoberturaVinculacionConfirmacion.resetItems(1);
      }

      if (this.$refs.seccionBeneficiariosProyectoConfirmacion) {
        this.$refs.seccionBeneficiariosProyectoConfirmacion.resetItems(1);
      }

      if (this.$refs.seccionDocumentosConfirmacion) {
        this.$refs.seccionDocumentosConfirmacion.resetItems(2);
      }

      if (this.$refs.seccionContactosConfirmacion) {
        this.$refs.seccionContactosConfirmacion.resetItems(1);
      }

      this.obtenerAvanceProyectoCns(idProyecto).then(() => {
        this.verificarDatosSecciones(this.datosAvanceProyecto);
        this.dialogLoaderVisible = false;
        this.dialogConfirmacion = true;
      });
    },

    ///Obener el avance de registro del proyecto
    async obtenerAvanceProyectoCns(proyectoId) {
      this.datosAvanceProyecto = {};
      await this.$store
        .dispatch(OBTENER_AVANCE_PROYECTO_CNS, { id: proyectoId })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosAvanceProyecto = res.data;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Mensaje",
              res.message
            );
          }
          //this.skeletonAvance[proyectoId] = false;
        })
        .catch(error => {
          console.log(error)
          //this.skeletonAvance[proyectoId] = false;
          this.dialogLoaderVisible = false;
          this.datosAvanceProyecto = {};
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async confirmarProyecto() {
      this.btnEnvioLoading = true;

      await this.$store
        .dispatch(CONFIRMAR_PROYECTO_CNS, {
          id: this.idProyectoSeleccionado,
          estadoId: 2
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogConfirmacion = false;
            this.dialogRegistro = false;
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnEnvioLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnEnvioLoading = false;
        });
    },

    //Verificar los datos necesarios para poder confirmar el proyecto y devolver un listado
    verificarDatosSecciones(datosProyecto) {
      this.porcentajeDatosFinancieros = 0;
      this.datosPendientes = 0;
      this.porcentajeAvance = 0;
      this.porcentajeAvance += 10;

      //this.informacionGeneralRegistrada = true;

      //Datos de la seccion información General del proyecto
      if (datosProyecto.informacionGeneral > 0) {
        this.informacionGeneralRegistrada = true;
        this.porcentajeAvance += 15;
      } else {
        this.informacionGeneralRegistrada = true;
        this.datosPendientes++;
      }

      //Datos de la seccion alcance del proyecto
      if (datosProyecto.alcance > 0) {
        this.alcanceRegistrado = true;
        this.porcentajeAvance += 10;
      } else {
        this.alcanceRegistrado = false;
        this.datosPendientes++;
      }

      //Datos de la seccion Datos Financieros del proyecto
      if (datosProyecto.datosFinancierosProyeccionRegistrados > 0) {
        this.datosFinancierosRegistrados = true;
        this.porcentajeAvance += 20;
      } else {
        this.datosPendientes++;
        this.datosFinancierosRegistrados = false;
        this.datosPendientes++;
      }

      if (datosProyecto.plazos > 0) {
        this.fechasPlazosRegistradas = true;
        this.porcentajeAvance += 10;
      } else {
        this.fechasPlazosRegistradas = false;
        this.datosPendientes++;
      }

      if (datosProyecto.coberturaVinculacionRegistrada > 0) {
        this.coberturaRegistrada = true;
        this.porcentajeAvance += 15;
      } else {
        this.coberturaRegistrada = false;
        this.datosPendientes++;
      }

      //Beneficiarios

      if (datosProyecto.cuentaBeneficiariosDirectos === 1) {
        if (datosProyecto.beneficiariosDirectos > 0) {
          this.beneficiariosRegistrados = true;
          this.porcentajeAvance += 5;
        } else {
          this.beneficiariosRegistrados = false;
          this.datosPendientes++;
        }
      } else {
        this.beneficiariosRegistrados = true;
        this.porcentajeAvance += 5;
      }

      if (datosProyecto.cuentaInstitucionesBeneficiarias === 1) {
        if (datosProyecto.institucinesBeneficiarias > 0) {
          this.institucionesBeneficiariasRegistradas = true;
          this.porcentajeAvance += 5;
        } else {
          this.institucionesBeneficiariasRegistradas = false;
          this.datosPendientes++;
        }
      } else {
        this.institucionesBeneficiariasRegistradas = true;
        this.porcentajeAvance += 5;
      }

      //Documentos
      if (datosProyecto.documentosCargados > 0) {
        this.documentosRegistrados = true;
        this.porcentajeAvance += 10;
      } else {
        this.documentosRegistrados = false;
        this.datosPendientes++;
      }

      if (datosProyecto.contactosRegistrados > 0) {
        this.contactosRegistrados = true;
        this.porcentajeAvance += 10;
      } else {
        this.contactosRegistrados = false;
        this.datosPendientes++;
      }

      if (this.porcentajeAvance > 100) {
        this.porcentajeAvance = 100;
      }
    },
  },

  created() {
    this.obtenerItems();
    this.obtenerEstadosProyecto();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard" },
      { title: "Proyectos" },
      { title: "CNS" },
    ]);
  },
  computed: {
    headers() {
      return [
        {
          text: "Código SICOOPERA",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        {
          text: "Código SIGEACI",
          align: "start",
          sortable: false,
          value: "refSigeaci",
        },
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "fecha",
        },
        {
          text: "Nombre del proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto",
        },
        {
          text: "Institución Ejecutora",
          align: "start",
          sortable: true,
          value: "nombreInstitucion",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyecto",
          filter: this.filtroEstado,
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },
  },
};
</script>
